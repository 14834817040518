<template>
  <div class="create-content-main">
    <div class="create-form">
      <h2 class="text-truncate-mess cp-4 mb-0">カテゴリ登録</h2>
      <form
        v-on:submit.prevent="CreateNewContent()"
        id="create-content"
        autocomplete="off"
      >
        <!-- <div class="row m-0 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cpx-4 mb-0">
            NO
          </label>
          <div class="cp-4 w-100">
            <b-form-input v-model="NO" disabled required></b-form-input>
          </div>
        </div> -->
        <div class="row m-0 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cpx-4 mb-0"
            >カテゴリ名<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12"
              type="text"
              v-model="category_name"
            />
          </div>
        </div>
        <!-- <div class="row m-0 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cpx-4 mb-0"
            >カテゴリID<span class="textMust">（必須）</span></label
          >
          <div class="cp-4 w-100">
            <input
              class="form-input col-sm-12 px-0"
              type="text"
              v-model="category_ID"
            />
          </div>
        </div> -->
        <div class="row m-0 row-input">
          <label class="col-sm-12 font-weight-bold custom-text cpx-4 mb-0"
            >コンテンツ一括設定</label
          >
          <div class="cp-4 w-100">
            <multiselect
              v-model="list_content"
              placeholder="コンテンツを選択"
              label="text"
              class="w-100 col-lg-6 px-0"
              track-by="value"
              :options="listContentOptions"
              :multiple="true"
              :taggable="false"
              :showNoResults="false"
              :select-label="'入力して選択する'"
              :deselect-label="'入力して削除する'"
              :selected-label="'選択された'"
              :loading="loadingContent"
              style="width: 100%"
              v-bind:class="{ 'error-input': errorContentIdRequired }"
            ></multiselect>
          </div>
        </div>
      </form>
      <div class="d-flex justify-content-center mt-4 py-4">
        <b-button
          v-on:click.prevent="CreateNewCategory()"
          :disabled="isLoading"
          variant="success"
          class="mr-4 btn-color"
          >登録・更新する</b-button
        >
        <b-button variant="secondary" class="mr-4" v-on:click="returnList()"
          >戻る</b-button
        >
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { Constants } from "../../utils/constants";
export default {
  name: "CreateCategory",
  data() {
    return {
      category_name: "",
      category_ID: "",
      isLoading: false,
      listContentOptions: [],
      list_content: [],
      errorContentIdRequired: false,
      loadingContent: false,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      page: 2,
    };
  },
  created() {
    const requestContent = {
      data: {
        shop_id: this.shop_id,
      },
      page: 1,
    };
    this.getListContents(requestContent);
    this.$store.commit("set", ["success", false]);
    this.$store.commit("set", ["message", ""]);
    this.$store.commit("set", ["error", false]);
  },
  computed: {
    ...mapGetters(["success", "message", "error", "listContents"]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.isLoading = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.isLoading = false;
      }
    },
    listContents() {
      document
        .getElementsByClassName("multiselect__content-wrapper")[0]
        .addEventListener("scroll", this.scrollContent);
      let listContent = [];
      listContent = this.listContents.data.map((item) => ({
        value: item.id,
        text: item.title,
      }));
      listContent.forEach((element) => {
        this.listContentOptions.push(element);
      });
      this.loadingContent = false;
    },
  },
  methods: {
    ...mapActions({ CreateCategoryAdmin: "CreateCategoryAdmin" }),
    ...mapActions({ getListContents: "getListContents" }),
    scrollContent() {
      if (
        Math.round(
          document.getElementsByClassName("multiselect__content-wrapper")[0]
            .scrollTop +
            document.getElementsByClassName("multiselect__content-wrapper")[0]
              .clientHeight
        ) ===
        document.getElementsByClassName("multiselect__content-wrapper")[0]
          .scrollHeight
      ) {
        this.loadingContent = true;
        const requestContent = {
          data: {
            shop_id: this.shop_id,
          },
          page: this.page,
        };
        this.page += 1;
        this.getListContents(requestContent);
      }
    },
    async CreateNewCategory() {
      this.isLoading = true;
      const shop_id = this.shop_id;
      // const regex = Constants.FULL_WIDTH.test(this.category_ID);
      let array_content_id = [];
      this.list_content.map((item) => {
        array_content_id.push(item.value);
      });
      const formCreateCategory = {
        shop_id: shop_id,
        category_name: this.category_name,
        // category_id: this.category_ID,
        content_id: array_content_id,
      };
      const dataReturn = await this.$store.dispatch(
        "CreateCategoryAdmin",
        formCreateCategory
      );
      if (dataReturn.success) {
        this.$router.go(-1);
      } else {
        this.isLoading = false;
      }
    },
    returnList() {
      // this.$router.push({ name: "List Category" });
      this.$router.go(-1);
    },
    changeSelect(value) {
      if (value === 3) {
        document
          .getElementsByClassName("multiselect__content-wrapper")[0]
          .addEventListener("scroll", this.scrollContent);
      }
    },
  },
};
</script>
<style scoped>
.create-form {
  box-shadow: 0 2px 10px rgb(0 0 0 / 20%);
  padding: 0px !important;
}
.textMust {
  color: #fff;
  background: red;
  padding: 2px 0px;
  border-radius: 5px;
  top: 0px;
  margin-left: 15px;
  font-size: 11px;
}
.custom-text {
  background-color: rgb(213, 213, 213);
  height: 40px;
  align-items: center;
  display: flex;
}
.row-input input {
  margin: 0px !important;
}
</style>
